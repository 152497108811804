<template>
  <FlashMessage :flash="flash" />
  <Suspense>
    <div id="layout" :class="{ safari }">
      <ErrorPage v-if="errorCode" :code="errorCode" />
      <router-view v-else-if="!errorRedirect" />
      <!-- work around vue-router bug with using teleport to the same container element -->
      <div style="display: none" />
    </div>
  </Suspense>
</template>

<script setup lang="ts">import { ref as _ref } from 'vue';

import { is } from "ts-safe-cast";
import { onErrorCaptured, provide } from "vue";
import { useRouter } from "vue-router";
import { DataError } from "$app/composables/useData";
import ErrorPage from "$app/pages/ErrorPage.vue";
import { ResponseError } from "$app/utils/request";
import FlashMessage, { flashMessageKey, type Flash } from "$app/components/FlashMessage.vue";
const safari = navigator.vendor.includes("Apple");
let errorCode = _ref<404 | 500 | null>(null);
const router = useRouter();
let errorRedirect = _ref(false);
onErrorCaptured<unknown>((error) => {
    if (error instanceof DataError) {
        if (error.response.status === 401) {
            void router.replace("/logout");
            errorRedirect.value = true;
        }
        else if (error.response.status === 403 && is<{
            redirect_path: string;
        }>(error.json, [5, [0, "redirect_path", 0]])) {
            void router.replace(error.json.redirect_path);
            errorRedirect.value = true;
        }
        else
            errorCode.value = error.response.status === 404 ? 404 : 500;
        return false;
    }
    else if (error instanceof ResponseError) {
        errorCode.value = 500;
    }
});
router.afterEach(() => {
    errorRedirect.value = false;
    errorCode.value = null;
});
let flash = _ref<Flash | null>(null);
provide(flashMessageKey, (message, status) => (flash.value = { message, status }));
</script>
