<template>
  <main
    class="error-page"
    style="
      align-content: center;
      background: rgb(var(--secondary));
      color: rgb(var(--contrast-secondary));
      text-align: center;
    "
  >
    <div class="error-page-content paragraphs" style="justify-items: center">
      <span class="icon icon-logo-icon" style="font-size: 10rem"></span>
      <h1>{{ heading }}</h1>
      <div>{{ text }}</div>
      <footer><router-link to="/">Go home?</router-link></footer>
    </div>
  </main>
</template>

<script setup lang="ts">import { computed as _computed } from 'vue';

const props = defineProps<{
    code: 404 | 500;
}>();
const heading = _computed(() => (props.code === 404 ? "Page not found" : "Something went wrong"));
const text = _computed(() => props.code === 404 ? "The thing you were looking for doesn't exist... Sorry!" : "Sorry about that. Please try again!");
</script>
