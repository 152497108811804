<template>
  <teleport to="body">
    <div
      v-if="props.flash"
      v-show="active"
      :class="props.flash.status === 'error' ? 'critical' : props.flash.status"
      role="alert"
      style="transition: var(--transition)"
      :style="show ? undefined : 'top: 0; opacity: 0'"
      @transitionend="active = show"
    >
      <div :class="`snackbar snackbar--${props.flash.status}`">
        {{ props.flash.message }}
      </div>
    </div>
  </teleport>
</template>

<script lang="ts">
import { inject, watch } from "vue";
import { createInjectionKey } from "$app/inject";
import { assertDefined } from "$app/utils/assert";
type FlashMessageStatus = "error" | "success";
export type Flash = {
    message: string;
    status: FlashMessageStatus;
};
export const flashMessageKey = createInjectionKey<(message: string, status: FlashMessageStatus) => void>("FlashMessage");
export const useFlashMessage = () => assertDefined(inject(flashMessageKey), "No FlashMessage instance found.");
//__BLOCK END__
//</script>

<script setup lang="ts">import { ref as _ref } from 'vue';

const props = defineProps<{
    flash: Flash | null;
}>();
let active = _ref(false);
let show = _ref(false);
let timeout: number | undefined;
watch(() => props.flash, () => {
    active.value = true;
    show.value = false;
    clearTimeout(timeout);
    requestAnimationFrame(() => (show.value = true));
    timeout = setTimeout(() => (show.value = false), 5000);
});
</script>
